<template>
  <div>
    <div v-if="isLoading" class="load"></div>
    <div v-else class="wrapper position-relative">
      <img src="./images/coupon_bg_01.jpg" alt="" width="100%" />
      <img src="./images/coupon_bg_02.jpg" alt="" width="100%" />
      <img src="./images/coupon_bg_03.jpg" alt="" width="100%" />
      <img src="./images/coupon_bg_04.jpg" alt="" width="100%" />
      <img src="./images/coupon_bg_05.jpg" alt="" width="100%" />

      <div class="next-btn" @click="handleNext"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      isLoading: "liffHuggies/isLoading",
    }),
  },
  methods: {
    ...mapActions({
      setLoading: "liffHuggies/setLoading",
    }),
    handleNext() {
      this.setLoading(true);
      this.$router.push({
        name: "LiffHuggiesCoupons",
        params: {
          org_code: this.$route.params.org_code
        }
      }).then(() => {
        this.setLoading(false);
      });
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  width: 100%;
  max-width: 576px;
  margin: auto;
}

.bg-2 {
  position: absolute;
  top: 240px;
  width: 95%;
  left: 15px;

  @media screen and (max-width: 576px) {
    top: 42vw;
    width: 95%;
    left: 2vw;
  }
}

.bg-3 {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.next-btn {
  background: url("./images/coupon_btn.png") no-repeat;
  background-size: contain;
  width: 500px;
  position: absolute;
  height: 120px;
  top: 554px;
  left: 62px;

  @media screen and (max-width: 576px) {
      width: 100vw;
    height: 21vw;
    top: 95vw;
    left: 11vw;
  }
}

.load {
  z-index: 500;
  position: fixed;
  top: 20%;
  left: 25%;
  height: 50px;
  width: 50%;
  background-image: url(./images/load.gif);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

</style>
